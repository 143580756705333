.container-image{
    width: 100%;
    height: 120px;
    position: relative;
}

.btn-close-photo{
    width: 25px;
    height: 25px;
    border-radius: 20px;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    right: 5px;
    top:5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-plate{
    width: 100%;
    height: 40px;
    background-color: red;
}