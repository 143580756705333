:root {
  --bg-onix: #242126;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-onix{
  background-color: var(--bg-onix);
}

a{
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer;
}

.cursor-pointer{
  cursor: pointer;
}

.container-btn-add{
  height: 50px;
  position: fixed;
  bottom: 10%;
  right: 22%;
  left: 22%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #3f8d47;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .container-btn-add{
    height: 50px;
    position: fixed;
    bottom: 65px;
    right: 40%;
    left: 40%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #3f8d47;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.container-btn-add-yellow{
  height: 50px;
  position: fixed;
  bottom: 10%;
  right: 20%;
  left: 20%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: yellow;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-btn-add a{
  color: white !important;
}

.category-selected{
  background-color:#cd0101 !important;
}

.category-selected-text{
  color: white;
}

.btn-custom{
  width: 35px;
  height: 35px;
  border: none;
  color: rgb(188, 0, 0);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  /* margin: 4px 2px; */
  border-radius: 50%;
  /* border: 20px !important; */
}

.btn-custom-call{
  width: 50px;
  height: 50px;
  border: none;
  background-color: red;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  /* margin: 4px 2px; */
  border-radius: 50%;
  /* border: 20px !important; */
}

.card-finish{
  background-color: #c9c7c7!important;
}

.text-blue{
  color: rgb(0, 128, 255);
}
.text-red{
  color:rgba(192, 57, 42, 1);
}
.text-grey{
  color: #575757;
}

.border-top-custom{
  border-top: 3px solid rgb(44, 100, 157) !important;
}

.bg-loader{
  position: fixed;
  top: 0;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 2000;
}

.bg-drawer{
  position: fixed;
  top: 0;
  width: 200px;
  height: 100%;
  
  background-color: rgba(0, 0, 0, .5);
  z-index: 2000;
}

.container-menu{
  background-color: rgb(0, 0,0);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.container-menu-item{
  height: 60px;
  width: 20%;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
}

.bg-drawer-content{
  background-color: white;
}

.font-size-14{
  font-size: 14px;
}
.font-size-12{
  font-size: 12px;
}
.font-size-11{
  font-size: 11px;
}
.font-size-10{
  font-size: 10px;
}
.font-size-9{
  font-size: 9px;
}

.img-category{
  width: 50px;
}

.btn-add-remove{
  width: 100px;
  height: 35px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.btn-add-remove-white{
  border:1px solid white;
}
.btn-add-remove-black{
  border:1px solid black;
}

.div-user{
  width: 25px;
  height: 25px;
  background-color: #575757;
  text-align: center;
  color: white;
  border-radius: 15px;
}

.btn-onix{
  --bs-btn-color: #fff !important;
    --bs-btn-bg: #151616 !important;
    --bs-btn-border-color: #198754;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #157347;
    --bs-btn-hover-border-color: #146c43;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #146c43;
    --bs-btn-active-border-color: #13653f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #198754;
    --bs-btn-disabled-border-color: #198754;
}

.img-menu{
  width: 30px;
}

.size-payment{
  min-height: 70px;
}

.bottom-payroll{
  background-color: red;
  border-radius: 10px;
}

.bg-card {
  background-color: #96ec9f !important;
}

.error-label{
  color:red !important;
}

.slider{
  width: 90%;
}