.btn-add{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #4CAF50; /* Color de fondo del botón */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    font-size: 24px; /* Tamaño del texto */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
    transition: background-color 0.3s ease; /* Efecto de transición */
}

.btn-add:hover {
    background-color: #45a049; /* Color de fondo al pasar el ratón por encima */
}

.btn-add:active {
    background-color: #3e8e41; /* Color de fondo al hacer clic */
}